/* eslint-disable no-constant-binary-expression */
/* eslint-disable no-undef */
/**
 * A futuro este será el archivo que se encargará de inicializar los eventos compartidos
 * El objetivo es dejar utag_events sólo con las clases de funcionalidad relacionadas con utag
 * pero que no tengan que ver con la inicialización de eventos
 */
import on from '../../utils/events_delegation'
import isInModal from '../../utils/isInModal'
import { getCleanedString, normalizeString } from '../../utils/utilities'

import { getUrlVars } from './partner_set_cookies'
import { sendUtagEvent } from './utag_events'

function updateUtagPageCurrency() {
  // eslint-disable-next-line no-jquery/no-other-utils
  utag_data['page_currency'] = $.cookie('currency')
  // eslint-disable-next-line no-jquery/no-other-utils
  utag_data.page_currency = $.cookie('currency')
}

export const getPageVariation = () => document.querySelector('html').dataset.fhiTest || 'control'

function productImpressionObserver(selector) {
  for (let i = 0; i < selector.length; ++i) {
    IB.utag_eec_events.productImpressionObserver.observe(selector[i])
  }
}

function sendProductImpressionObserver() {
  const hotels_info_with_filtering = document.querySelectorAll('div.wrapper.hotels-info-with-filtering')
  const hotel_list = document.querySelectorAll('div.block.hotel-list.js-webpack-block')
  const best_offers_blocks = document.querySelectorAll('.best-offers-block')

  if (hotels_info_with_filtering.length) {
    productImpressionObserver(hotels_info_with_filtering)
  }
  if (hotel_list.length) {
    productImpressionObserver(hotel_list)
  }
  if (best_offers_blocks.length) {
    productImpressionObserver(best_offers_blocks)
  }
}

// TODO PY05032 No sabemos para que es esto, se deja aqui para una futura revisión

function listenerDestinationMapEvent() {
  const destination_event = window['addEventListener']
  const messageEvent = 'message'

  destination_event(
    messageEvent,
    function (e) {
      if (e.data === 'view hotel') {
        sendUtagEvent({
          data: {
            event_name: '',
            event_cat: 'destinations',
            event_act: 'interactive map',
            event_lbl: 'see hotel',
            event_purpose: '',
            event_structure: '',
            page_variation: isFhiPage ? fhiPageType : 'control',
          },
        })
      }
      if (e.data === 'view photos') {
        sendUtagEvent({
          data: {
            event_name: '',
            event_cat: 'destinations',
            event_act: 'interactive map',
            event_lbl: 'see photos',
            event_purpose: '',
            event_structure: '',
            page_variation: isFhiPage ? fhiPageType : 'control',
          },
        })
      }
      if (e.data === 'drilldown') {
        sendUtagEvent({
          data: {
            event_name: '',
            event_cat: 'destinations',
            event_act: 'interactive map',
            event_lbl: 'click',
            event_purpose: '',
            event_structure: '',
            page_variation: isFhiPage ? fhiPageType : 'control',
          },
        })
      }
    },
    false
  )
}

export function initializeSharedEvents() {
  /* Hotel information  html: open_item, review_helper, review_decorator*/
  document.querySelectorAll('.utag-see-complete-opinion').forEach(element => {
    element.addEventListener('click', function () {
      sendUtagEvent({
        data: {
          event_name: 'hotel_reviews',
          event_cat: 'hotel information',
          event_act: 'see opinions',
          event_lbl: 'read complete opinion',
          event_purpose: 'inspiration',
          event_structure: isInModal(this) ? 'popup' : 'body',
        },
      })
    })
  })

  /* gueest_opinions, hotel_information_ratings */
  document.addEventListener('click', function (event) {
    var target = event.target

    if (target.matches('.utag-see-hotel-reviews')) {
      sendUtagEvent({
        data: {
          event_name: 'hotel_reviews',
          event_cat: 'hotel information',
          event_act: 'see opinions',
          event_lbl: 'see reviews',
          event_purpose: 'inspiration',
          event_structure: 'body',
        },
      })
    }
  })
  /* multimedia_gallery_helper, tour, gallery, virtual_tour */

  document.querySelectorAll('.utag-view-360').forEach(element => {
    element.addEventListener('click', function () {
      sendUtagEvent({
        data: {
          event_name: 'hotel_multimedia_360',
          event_cat: 'hotel information',
          event_act: 'see multimedia info',
          event_lbl: '360',
          event_purpose: 'inspiration',
          event_structure: 'popup',
          page_variation: getPageVariation(),
        },
      })
    })
  })
  /* Fin Hotel information */
  /* lightbox_decorator, MainNavUser.vue, form(agency_sessions), header_pro,
    header_staragent, header_user_image_icon, header(views/layouts),
    main_nav_links(view/layouts), main_nav_links(nhp_presidential),
    mobile_header(nhp_presidential), header(shared/fb), form(staragent/session)
  */

  on({
    eventName: 'click',
    selector: '.utag-ib-register',
    handler() {
      const loginButton = document.querySelector('.utag-ib-register')
      const registerType = loginButton.dataset.utagIbRegisterType || ''

      sendUtagEvent({
        data: {
          event_name: 'select_sign_up',
          event_cat: 'lead',
          event_act: 'click sign up',
          event_lbl: normalizeString(registerType),
          event_purpose: 'loyalty',
          event_structure: normalizeString(registerType),
        },
      })
    },
  })

  /** single_offer(multi_offers_slider), offer(offers_index_with_filter) */
  document.querySelectorAll('.utag-offer-view').forEach(element => {
    element.addEventListener('click', function (event) {
      const event_lbl = event.target.getAttribute('data-offer-title')

      sendUtagEvent({
        data: {
          event_name: 'offers_apply',
          event_cat: 'offers',
          event_act: 'apply offer',
          event_lbl,
          event_purpose: 'booking',
          event_structure: 'body',
        },
      })
    })
  })

  // CALL CENTER EVENTS
  // call_click_contact
  document.addEventListener('click', function (event) {
    var target = event.target
    let structure = ''

    // botón en header
    if (
      target.matches('.main-header-content .mobile-call-center') ||
      target.matches('.people-content .three-more-rooms')
    ) {
      structure = 'header'
    }

    // botón en header en mobile
    if (target.matches('.mobile-header-cnt span.mobile-button-text')) {
      structure = 'header'
    }

    // botón en header en rebranding
    if (target.matches('span.reb-header__nav-item.mobile-call-center')) {
      structure = 'header'
    }

    // click contact button - popup
    if (target.matches('.utag-contact-button')) {
      structure = 'popup'
    }
    if (target.matches('.hotel-gallery__contact .mobile-call-center')) {
      structure = 'popup'
    }

    // click contact button - footer
    if (target.matches('.footer-contact-container .mobile-call-center')) {
      structure = 'footer'
    }
    if (target.matches('.footer-contact-container .footer-contact-form')) {
      structure = 'footer'
    }

    // click contact button - body
    if (target.matches('a.utag-ib-contact')) {
      structure = 'body'
    }

    if (structure !== '') {
      sendUtagEvent({
        data: {
          event_name: 'call_click_contact',
          event_cat: 'call center',
          event_act: 'click contact button',
          event_lbl: '',
          event_structure: structure,
          event_purpose: 'information',
          page_variation: getPageVariation(),
        },
      })
    }
  })

  // call_to_call
  document.addEventListener('click', function (event) {
    const target = event.target
    let structure = ''

    if (target.matches('.mobile-fastbooking  a.secondary-btn')) {
      structure = 'sticky'
    }

    if (target.matches('.mobile-cta-sticky-bar .call-btn')) {
      structure = 'sticky'
    }

    if (target.matches('.phone-list-number a.big-number, #box-call-center a')) {
      structure = 'popup'
    }

    if (target.matches('#phone-list-numbers ul li p a')) {
      structure = 'popup'
    }

    if (target.matches('.phone-number.mobile-only')) {
      structure = 'body'
    }

    if (structure !== '') {
      sendUtagEvent({
        data: {
          event_name: 'call_to_call',
          event_cat: 'call center',
          event_act: 'click to call',
          event_lbl: '',
          event_structure: structure,
          event_purpose: 'information',
          page_variation: getPageVariation(),
        },
      })
    }
  })

  // BOOKING EVENTS
  // book_room
  /* price_and button */
  document.addEventListener('click', function (event) {
    const pageVariation = document.querySelector('html').dataset.fhiTest === undefined ? 'rational' : 'inmersive'
    var target = event.target

    if (target.matches('.utag-room-book-now, .room-list a.btn-with-arrow')) {
      const utag_hotel_info = target.dataset.utagHotelInfo

      const data = {
        data: {
          event_name: 'book_room',
          event_cat: 'book now',
          event_act: 'book room',
          event_lbl: target.dataset.hotelRoomName,
          event_structure: 'body',
          event_purpose: 'booking',
          page_variation: pageVariation,
        },
      }

      if (pageVariation === 'rational') {
        if (utag_hotel_info !== undefined) {
          data.data = Object.assign({}, data.data, utag_hotel_info)

          // Initilize new visitor session id simulting GA sessions
          IB.VisitorSessionId.initNewVisitorSessionId()
        }
      }

      sendUtagEvent(data)
    }

    if (target.matches('.modal-compare-rooms .btn-floating-fastbooking')) {
      const dataRoomId = event.target.getAttribute('data-room-id')
      let room_label = ''
      const selectedRoom = document.querySelector(`#modal-choose-rooms .room.selected[data-id="${dataRoomId}"]`)

      if (selectedRoom) {
        room_label = selectedRoom.dataset.roomId
      }

      sendUtagEvent({
        data: {
          event_name: 'book_room',
          event_cat: 'book now',
          event_act: 'book room',
          event_lbl: room_label,
          event_structure: 'body',
          event_purpose: 'booking',
          pageVariation,
        },
      })
    }
  })

  /**
   * Offers events
   */
  document.addEventListener('click', function (event) {
    var target = event.target
    let event_lbl = ''

    if (target.matches('.utag-special-offers-all')) {
      event_lbl = 'highlight'
    } else if (target.matches('.utag-special-offers-all-menu')) {
      event_lbl = 'menu'
    }

    if (!event_lbl.length) return

    const event_name = 'offers'
    const event_cat = 'offers'
    const event_act = 'view all deals'
    const event_purpose = 'inspiration'
    const event_structure = 'body'

    sendUtagEvent({
      data: {
        event_name,
        event_cat,
        event_act,
        event_lbl,
        event_purpose,
        event_structure,
      },
    })
  })

  document.addEventListener(
    'click',
    function (event) {
      var target = event.target

      if (target.matches('.landing-typekit-header #open-legal')) {
        sendUtagEvent({
          data: {
            event_cat: 'offers',
            event_name: 'offers',
            event_act: 'view offer conditions',
            event_lbl: '',
            event_purpose: 'information',
            event_structure: 'body',
          },
        })
      }
    },
    { once: true }
  )

  /**
   * Social events
   */
  document.addEventListener('click', function (event) {
    var target = event.target
    let event_act = ''
    let event_lbl = ''
    let event_purpose = ''
    const event_structure = event.target.closest('.js-main-footer') ? 'footer' : 'body'

    if (
      target.matches('.footer-contact-left .social-links a, .social-list a, .footer-contact-container .social-links a')
    ) {
      event_act = 'follow'
      event_lbl = target.dataset.networkName
      event_purpose = 'loyalty'
    } else if (target.matches('.last-search-card a.shared')) {
      event_act = 'share search'
      event_lbl = target.dataset.hotel
      event_purpose = 'loyalty'
    } else if (target.matches('.utag-shared-hotel')) {
      event_act = 'share search'
      event_lbl = target.dataset.hotelNameShared
      event_purpose = 'loyalty'
    } else if (
      target.matches(
        '.share-list a, .share-list button, .share-container a.share, .social-container a, .social-share-list .share'
      )
    ) {
      event_act = 'send search by'
      event_lbl = target.dataset.networkName
      event_purpose = 'information'
    }

    if (!event_act.length) return

    sendUtagEvent({
      data: {
        event_name: 'social_events',
        event_cat: 'social events',
        event_act,
        event_lbl,
        event_purpose,
        event_structure,
      },
    })
  })

  /**
   * Interacción con la cabeza del menú principal de la web
   **/
  document.addEventListener('click', function (event) {
    var target = event.target

    let event_lbl = ''
    let event_purpose = 'information'

    if (target.matches('.utag-cio-main-menu, li .utag-cio-mobile-main-menu')) {
      event_lbl = 'check in online'
    } else if (target.matches('.utag-mro-main-menu, li .utag-mro-mobile-main-menu')) {
      event_lbl = 'my room online'
      event_purpose = 'postbooking'
    } else if (target.matches('.utag-bookings-lightbox, li.utag-bookings-mobile-main-menu')) {
      event_lbl = 'modify booking'
    }

    if (!event_lbl.length) return

    const event_act = 'manage my bookings'
    const event_cat = 'main-menu menu'

    sendUtagEvent({
      data: {
        event_name: 'menu_main',
        event_cat,
        event_act,
        event_lbl,
        event_purpose,
        event_structure: 'header',
      },
    })
  })

  document.addEventListener('click', function (event) {
    const target = event.target

    if (target && target.matches('.language-selector li a')) {
      const main_header = target.closest('.main-header')
      const event_structure = main_header ? 'header' : 'footer'

      sendUtagEvent({
        data: {
          event_name: 'change_language',
          event_cat: 'change page info',
          event_act: 'language',
          event_lbl: target.dataset.locale,
          event_purpose: 'information',
          event_structure,
          page_variation: getPageVariation(),
        },
      })
    }
  })

  /* Evento moneda - change_currency */
  document.addEventListener('change', function (event) {
    const target = event.target

    if (target && target.matches('form.currency-form select')) {
      const event_cat = 'change currency info'
      const event_act = 'currency'
      const event_lbl = target.value

      // Actualizamos el valor de utag page currency con el valor de la cookie cuando se produzca un cambio de moneda
      updateUtagPageCurrency()

      sendUtagEvent({
        data: {
          event_name: 'change_currency',
          event_cat,
          event_act,
          event_lbl,
          event_purpose: 'booking',
          event_structure: target.closest('.footer-selectors') !== null ? 'footer' : 'header',
        },
      })
    } else if (target.matches('.booking-links .modify-booking, .bottom-links a.link-modify')) {
      const event_cat = 'manage my booking'
      const event_act = 'modify booking'
      const event_lbl = ''

      sendUtagEvent({
        data: {
          event_name: 'manage_booking',
          event_cat,
          event_act,
          event_lbl,
          event_purpose: 'postbooking',
          event_structure: 'body',
        },
      })
    } else if (target.matches('.booking-links .print')) {
      const event_cat = 'manage my booking'
      const event_act = 'print booking'
      const event_lbl = ''

      sendUtagEvent({
        data: {
          event_name: 'manage_booking',
          event_cat,
          event_act,
          event_lbl,
          event_purpose: 'postbooking',
          event_structure: 'body',
        },
      })
    } else if (target.matches('.repeat-reservation')) {
      const event_cat = 'manage my booking'
      const event_act = 'repeat booking'
      const event_lbl = ''

      sendUtagEvent({
        data: {
          event_name: 'manage_booking',
          event_cat,
          event_act,
          event_lbl,
          event_purpose: 'booking',
          event_structure: 'body',
        },
      })
    } else if (target.matches('.booking-links .exchange-points')) {
      const event_cat = 'manage my booking'
      const event_act = 'exchange booking'
      const event_lbl = ''

      sendUtagEvent({
        data: {
          event_name: 'manage_booking',
          event_cat,
          event_act,
          event_lbl,
          event_purpose: 'booking',
          event_structure: 'body',
        },
      })
    } else if (target.matches('.reserve-index-list li.cancel a')) {
      const event_cat = 'manage my booking'
      const event_act = 'cancel booking'
      const event_lbl = ''

      sendUtagEvent({
        data: {
          event_name: 'manage_booking',
          event_cat,
          event_act,
          event_lbl,
          event_purpose: 'booking',
          event_structure: 'body',
        },
      })
    } else if (target.matches('.booking-links .complain')) {
      const event_cat = 'manage my booking'
      const event_act = 'complain'
      const event_lbl = ''

      sendUtagEvent({
        data: {
          event_name: 'manage_booking',
          event_cat,
          event_act,
          event_lbl,
          event_purpose: 'postbooking',
          event_structure: 'body',
        },
      })
    }
  })

  document.querySelectorAll('.js-hotel-map-link').forEach(element => {
    element.classList.add('utag-initialized')

    if (element.classList.contains('utag-initialized')) {
      return
    }

    element.addEventListener('click', function () {
      sendUtagEvent({
        data: {
          event_name: 'hotel_map',
          event_cat: 'hotel information',
          event_act: 'view map',
          event_lbl: '',
          event_purpose: 'inspiration',
          event_structure: 'body',
        },
      })
    })
  })

  /*
  Utag home banner. Present in:
    - home megabanner slider
    - video looping
    - video with fastbooking
    - video with vertical fastbooking
  */
  on({
    eventName: 'click',
    selector: '.utag-header-banner-promotion',
    handler() {
      let utag_data = {
        enh_action: 'promo_click',
        event_name: 'select_promotion',
        event_cat: 'ecommerce',
        event_act: 'promo click',
        promotion_creative: ['home|home|video with fastbooking'],
        event_purpose: 'booking',
        event_structure: 'body',
      }

      let item_data = {}
      const promotion_id = this.dataset.promotionId
      const promotion_name = this.dataset.promotionName
      const slide_index = this.closest('.js-slide-element')?.dataset.slideIndex

      item_data = promotion_id && { ...item_data, promotion_id: [promotion_id] }
      item_data = promotion_name && { ...item_data, event_lbl: promotion_name, promotion_name: [promotion_name] }
      item_data = slide_index && { ...item_data, promotion_position: [parseInt(slide_index, 10) + 1] }

      utag_data = { ...utag_data, ...item_data }

      if (item_data.promotion_id[0] !== '' && item_data.promotion_name[0] !== '') {
        sendUtagEvent({ data: utag_data })
      }
    },
  })

  //---------------------------------- Home with fastbooking --------------------------------------//
  if (document.querySelector('.home-page .utag-home-banner') !== null) {
    document
      .querySelector('.home-page .utag-home-banner')
      .addEventListener('homeWithFastbookingAnalytics', function () {
        const home_items = document.querySelectorAll('.home-page .utag-home-banner .items .item')
        const home_length = document.querySelectorAll('.home-page .utag-home-banner .items .item').length

        var utag_data = {
          enh_action: 'impression list',
          event_cat: 'ecommerce',
          event_act: 'promotion impression list',
          non_interaction: '1',
          promotion_id: [],
          promotion_name: [],
          promotion_creative: [],
          promotion_position: [],
        }

        let position = 0
        for (let index = 0; index < home_length; index++) {
          if (home_items[index].classList.contains('initial')) {
            utag_data.promotion_id.push('wave-of-change')
            utag_data.promotion_name.push('wave of change')
            utag_data.promotion_creative.push('home|home|video with fastbooking')
            utag_data.promotion_position.push((position + 1).toString())
            position += 1
          } else if (home_items[index].id === 'megabanner-item-template') {
            const dataHotelName = home_items[index].querySelector('[data-hotel-name]')
            if (dataHotelName && dataHotelName.dataset.hotelName.length > 0) {
              const hotel_name = dataHotelName.dataset.hotelName.toLowerCase()
              utag_data.promotion_id.push(hotel_name.replaceAll(' ', '-'))
              utag_data.promotion_name.push(hotel_name)
              home_items[index].children[0].setAttribute('data-promotion-id', hotel_name.replaceAll(' ', '-'))
              home_items[index].children[0].setAttribute('data-promotion-name', hotel_name)
              utag_data.promotion_creative.push('home|home|video with fastbooking')
              utag_data.promotion_position.push((position + 1).toString())
              position += 1
            }
          } else {
            const dataPromoId = home_items[index].querySelector('[data-promotion-id]')
            const dataPromoName = home_items[index].querySelector('[data-promotion-name]')
            if (
              dataPromoId &&
              dataPromoName &&
              dataPromoId.dataset.promotionId !== '' &&
              dataPromoName.dataset.promotionName !== ''
            ) {
              utag_data.promotion_id.push(dataPromoId.dataset.promotionId)
              utag_data.promotion_name.push(dataPromoName.dataset.promotionName)
              utag_data.promotion_creative.push('home|home|video with fastbooking')
              utag_data.promotion_position.push((position + 1).toString())
              position += 1
            }
          }
        }

        if (
          utag_data.promotion_id !== [] &&
          utag_data.promotion_name !== [] &&
          utag_data.promotion_creative !== [] &&
          utag_data.promotion_position !== []
        ) {
          sendUtagEvent({ data: utag_data })
        }
      })
  }

  /* Evento play del modal video */
  document.querySelectorAll('.js-modal-video, .js-video').forEach(element => {
    element.addEventListener('click', function () {
      sendUtagEvent({
        data: {
          event_name: 'multimedia',
          event_cat: 'videos',
          event_act: 'play',
          event_lbl: 'youtube-video',
          event_purpose: 'inspiration',
          event_structure: 'body',
        },
      })
    })
  })

  const toggleViewMoreButton = document.querySelector('.js-link-see-more')
  const viewMoreButton = toggleViewMoreButton?.querySelector('.utag-see-more')

  viewMoreButton?.addEventListener('click', function () {
    const block = viewMoreButton.closest('.js-pack-block')
    const blockName = block.dataset.blockLayout.replace('_', ' ')
    const blockTitle = block.querySelector('.block-title')
    const category = blockName
    const action = 'see more'
    const label =
      blockTitle.dataset.utagBlockTitle !== undefined ? blockTitle.dataset.utagBlockTitle : blockTitle.innerText
    sendUtagEvent({
      data: {
        event_name: 'introduction_module',
        event_cat: category,
        event_act: action,
        event_lbl: getCleanedString(label),
        event_purpose: 'inspiration',
        event_structure: 'body',
      },
    })
  })

  document.querySelector('.utag-contact-ibe')?.addEventListener('submit', event => {
    var target = event.target

    if (target.matches('.utag-contact-ibe')) {
      var reasonSelect = document.querySelector('.contact-us form #reason')
      var selectedOption = reasonSelect.selectedOptions[0]
      const event_act = selectedOption.dataset.utagType

      reasonSelect = document.querySelector('.contact-us form #type_of_reason')
      selectedOption = reasonSelect.selectedOptions[0]
      const event_lbl = selectedOption.dataset.utagReason

      sendUtagEvent({
        data: {
          event_name: 'contact_form',
          event_cat: 'contact form',
          event_act,
          event_lbl,
          event_purpose: 'information',
          event_structure: 'body',
        },
      })
    }
  })

  // TODO PY05032 Reviw this, where is showing
  document.querySelectorAll('.hotel-list-link').forEach(hotelList => {
    hotelList.addEventListener('click', () => {
      sendProductImpressionObserver()
    })
  })

  // TODO PY05032 Reviw this, where is showing
  // Send event on select forms with options.
  // you can see a example on unsubscribe.html.erb on web

  document.querySelector('form[data-form-event-type="collection-radio-button"]')?.addEventListener('submit', e => {
    const event_cat = e.target.data('form-event-category')
    const event_act = e.target.data('form-event-action')

    // event_lab should be a selected option on form
    const nameRadio = e.target.data('form-event-radio-to-send')

    const selectdRadio = e.target.find(`[name="unsubscribe[${nameRadio}]"]:checked`)
    sendUtagEvent({
      data: {
        event_name: '',
        event_cat,
        event_act,
        event_lbl: selectdRadio.val(),
        event_purpose: '',
        event_structure: '',
        page_variation: isFhiPage ? fhiPageType : 'control',
      },
    })
  })

  listenerDestinationMapEvent()

  // ---------------------------------------------------------------- //

  // TODO PY05032 No encontrados

  /* eslint-disable no-jquery/no-other-utils */
  /* eslint-disable no-jquery/no-each-collection */
  /* eslint-disable no-jquery/no-data */
  /* eslint-disable no-jquery/no-text */
  /* eslint-disable no-jquery/no-val */
  /* eslint-disable no-jquery/no-attr */
  /* eslint-disable no-jquery/no-class */
  /* eslint-disable no-jquery/no-is */
  /* eslint-disable no-jquery/no-jquery-constructor */
  /* eslint-disable no-jquery/no-other-methods */
  // TODO PY05032 LEGAL NOTICE
  $(document).on('click', '.legal-notice', function () {
    const category = 'hotel information'
    const action = 'see legal content'
    sendUtagEvent({
      data: {
        event_name: '',
        event_cat: category,
        event_act: action,
        event_lbl: '',
        event_purpose: '',
        event_structure: '',
        page_variation: isFhiPage ? fhiPageType : 'control',
      },
    })
  })

  // TODO PY05032 JOB
  $(document).on('click', '.job-vacancies a.secondary-btn', function () {
    const category = 'jobs iberostar'
    const action = 'see career oportunities'
    const label = ''
    sendUtagEvent({
      data: {
        event_name: '',
        event_cat: category,
        event_act: action,
        event_lbl: label,
        event_purpose: '',
        event_structure: '',
      },
    })
  })

  $(document).on('click', '.send-cv a.secondary-btn', function () {
    const category = 'jobs iberostar'
    const action = 'enter cv'
    const label = ''
    sendUtagEvent({
      data: {
        event_name: '',
        event_cat: category,
        event_act: action,
        event_lbl: label,
        event_purpose: '',
        event_structure: '',
      },
    })
  })

  // TODO PY05032 DOWNLOAD
  $(document).on('click', '.download', function () {
    const category = 'download'
    const action = 'information room'
    const label = $(this).data('utag-hotel-download-pdf') ? $(this).data('utag-hotel-download-pdf') : ''
    const page_section = $(this).data('utag-page-section')
      ? $(this).data('utag-page-section')
      : utag_data['page_section']
    const page_type = $(this).data('utag-page-type') ? $(this).data('utag-page-type') : utag_data['page_type']
    const page_category = $(this).data('utag-page-category')
      ? $(this).data('utag-page-category')
      : utag_data['page_category']
    utag_data['page_section'] = page_section
    utag_data['page_type'] = page_type
    utag_data['page_category'] = page_category
    sendUtagEvent({
      data: {
        event_name: '',
        event_cat: category,
        event_act: action,
        event_lbl: label,
        event_purpose: '',
        event_structure: '',
      },
    })
  })
  // TODO PY05032 WEB CHECK IN
  $(document).on('submit', 'form#form_wci', function () {
    const origin_options = ['confirmation-booking-page', 'confirmation-booking-email', 'pre-stay-email'] // Add black list label options
    const origin_checkin = getUrlVars()['origin_checkin']
    const origin_checkin_in_url = indexOf(origin_options, origin_checkin)

    const category = 'checkin online'
    const action = 'send check in'
    const label = origin_checkin_in_url !== -1 ? origin_checkin.replace(/-/g, ' ') : 'web check in'
    sendUtagEvent({
      data: {
        event_name: '',
        event_cat: category,
        event_act: action,
        event_lbl: label,
        event_purpose: '',
        event_structure: '',
      },
    })
  })

  // TODO PY05032 MANAGE MY BOOKINGS

  $(document).on('click', '.booking-categories-cnt li a', function () {
    const category = 'manage my booking'
    const action = 'filter my booking'
    const label = $(this).data('utag-my-booking-filter')
    sendUtagEvent({
      data: {
        event_name: '',
        event_cat: category,
        event_act: action,
        event_lbl: label,
        event_purpose: '',
        event_structure: '',
      },
    })
  })

  // TODO PY05032 OFFERS

  $(document).on('click', '.special-offers-banner .utag-promo-link', function () {
    const event_cat = 'offers detail'
    const event_act = 'offers highlight'
    const event_lbl = $(this).data('utag-eec').promotion_position
    sendUtagEvent({
      data: {
        event_name: '',
        event_cat,
        event_act,
        event_lbl,
        event_purpose: '',
        event_structure: '',
      },
    })
  })
  // TODO PY05032 MY IBEROSTAR INFO
  $(document).on('click', '.my-iberostar-info a', function () {
    const category = 'searcher'
    const action = 'more information'
    sendUtagEvent({
      data: {
        event_name: '',
        event_cat: category,
        event_act: action,
        event_lbl: '',
        event_purpose: '',
        event_structure: '',
      },
    })
  })

  // TODO PY05032 INTERACTIVE MAP

  $(document).on('click', '.b-destination-map .iframe-container', function () {
    const destination = $(this).data('destination')
    sendUtagEvent({
      data: {
        event_name: '',
        event_cat: 'destinations',
        event_act: 'interactive map',
        event_lbl: destination,
        event_purpose: '',
        event_structure: '',
      },
    })
  })
  // TODO PY05032 JOIN NOW
  $(document).on('click', '.join-now', function () {
    const category = 'affiliates'
    const action = 'join now'
    const label = ''
    sendUtagEvent({
      data: {
        event_name: '',
        event_cat: category,
        event_act: action,
        event_lbl: label,
        event_purpose: '',
        event_structure: '',
      },
    })
  })

  // TODO PY05032 FREE TOUR
  $(document).on('click', '#utag-freetours-form .utag-form-button', function () {
    if ($('.utag-form').parsley().validate()) {
      const hotel = $('#hotel_id option:selected').data('utag-hotel').toLowerCase()
      const freetour_name = $('#freetour_id option:selected').data('utag-name').toLowerCase()
      const checkin = $('#check_in').val()

      let pax = ''
      if (parseInt($('#pax option:selected').val(), 10) < 10) {
        pax = `0${$('#pax option:selected').text()}`
      } else {
        pax = $('#pax option:selected').text()
      }

      const category = 'freetour form'
      const action = `${hotel}-${freetour_name}`
      const label = `${pax}-${checkin}`
      sendUtagEvent({
        data: {
          event_name: '',
          event_cat: category,
          event_act: action,
          event_lbl: label,
          event_purpose: '',
          event_structure: '',
        },
      })
    }
  })

  // TODO PY05032 LEAD
  $(document).on('click', '.utag-lead-event', function () {
    sendUtagEvent({
      data: {
        event_name: '',
        event_cat: 'lead',
        event_act: 'click sign up',
        event_lbl: 'offers',
        event_purpose: '',
        event_structure: '',
      },
    })
  })

  $(document).on('click', 'input.utag-pre-lead', function () {
    const checked = $(this).is(':checked')
    const category = 'lead'
    const action = 'pre lead'
    const label = ''
    if (checked === true) {
      sendUtagEvent({
        data: {
          event_name: '',
          event_cat: category,
          event_act: action,
          event_lbl: label,
          event_purpose: '',
          event_structure: '',
        },
      })
    }
  })

  $(document).on('click', '.legal-text-with-title a, .offer-layout a, .header-customized a', function () {
    const href = $(this).attr('href') || ''
    if (href.includes('lightbox_type=registration') && !$(this).hasClass('utag-ib-register')) {
      sendUtagEvent({
        data: {
          event_name: '',
          event_cat: 'lead',
          event_act: 'click sign up',
          event_lbl: 'offers',
          event_purpose: '',
          event_structure: '',
        },
      })
    }

    if (href.includes('lightbox_type=login') && !$(this).hasClass('utag-ib-login')) {
      sendUtagEvent({
        data: {
          event_name: '',
          event_cat: 'lead',
          event_act: 'click sign up',
          event_lbl: 'offers',
          event_purpose: '',
          event_structure: '',
        },
      })
    }
  })

  // TODO PY05032 WEDDING

  $(document).on('submit', 'form.wedding-form', function () {
    const type_request = $('#type_request :checked').data().utagValue
    const type_event = $('#type_event :checked').data().utagValue
    const country = $('#country').val() ? getCleanedString($('#country').val().trim().toLowerCase()) : ''
    const wedding_date = `${$('#date_day :checked').val()}/${+$('#date_month :checked').val()}/${$(
      '#date_year :checked'
    ).val()}`
    const destination = $('#preferred_destination :checked').data().utagValue
    const number_guest = $('#number_guests').val()

    const lbl = `${type_request}-${type_event}-${country}-${wedding_date}-${destination}-${number_guest}`

    sendUtagEvent({
      data: {
        event_name: 'quote_wedding',
        event_cat: 'wedding',
        event_act: 'request-event-origin-dd/MM/YYYY-destination-guest',
        event_lbl: lbl,
        event_purpose: 'booking',
        event_structure: 'body',
      },
    })
  })
  // TODO PY05032 MAIN-MENU
  $(document).on('click', '.js-notification-link', function () {
    const category = 'menu-main menu'
    const action = 'alerts'
    const label = $(this).data('notification-title-en')
    sendUtagEvent({
      data: {
        event_name: '',
        event_cat: category,
        event_act: action,
        event_lbl: label,
        event_purpose: '',
        event_structure: '',
      },
    })
  })

  $(document).on('click', '.js-experience-menu-link', function () {
    const event_cat = 'menu-main menu'
    const event_act = 'hotels and destinations'
    let event_lbl = ''

    const experience = $(this).data('utag-experience')
    if (experience === 'all-in') {
      event_lbl = 'all inclusive'
    } else if (experience === 'star_prestige') {
      event_lbl = 'star prestige'
    } else {
      event_lbl = experience
    }

    sendUtagEvent({
      data: {
        event_name: 'menu_main',
        event_cat,
        event_act,
        event_lbl,
        event_purpose: '',
        event_structure: '',
      },
    })
  })

  $(document).on('click', '.js-all-experiences-link-main-menu', function () {
    const event_cat = 'main-menu menu'
    const event_act = 'view all hotels and destinations'
    const event_lbl = 'specialty'

    sendUtagEvent({
      data: {
        event_name: 'menu_main',
        event_cat,
        event_act,
        event_lbl,
        event_purpose: 'information',
        event_structure: 'header',
      },
    })
  })

  // TODO PY05032 ROOM INFO, utag-room-info no in the html
  $(document).on('click', '.hotel-one-landing-page a.utag-room-info', function () {
    const category = 'hotel information'
    const action = 'view more room detail'
    const label = ''
    sendUtagEvent({
      data: {
        event_name: '',
        event_cat: category,
        event_act: action,
        event_lbl: label,
        event_purpose: '',
        event_structure: '',
        page_variation: isFhiPage ? fhiPageType : 'control',
      },
    })
  })

  // TODO PY05032  Hotel info Video click, video-filters, utag-info-video  no in the html
  $(document).on('click', '.video-filters, .utag-info-video', function () {
    const category = 'hotel information'
    const action = 'see multimedia info'
    const label = 'videos'
    sendUtagEvent({
      data: {
        event_name: '',
        event_cat: category,
        event_act: action,
        event_lbl: label,
        event_purpose: '',
        event_structure: '',
        page_variation: isFhiPage ? fhiPageType : 'control',
      },
    })
  })
}
