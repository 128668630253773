/**
 * Checks if an element is inside a modal
 */
const isInModal = elem => {
  if (!elem) return false

  return Boolean(elem.closest('.js-modal') || elem.closest('.fancybox-container'))
}

export default isInModal
