/**
 * @param {object} event
 * @param {string} event.eventName - Event name (click, keyup, etc)
 * @param {string} event.selector - Node triggering the event
 * @param {string} event.parent - Node event listener
 * @param {function} event.handler - Callback
 * @example on({ eventName: 'click', selector: '.js-class',  handler: 'handlerButton', once: false})
 *
 */
function on({ eventName, selector, parent = document, handler, once = false }) {
  parent.addEventListener(
    eventName,
    function (event) {
      const elements = parent.querySelectorAll(selector)
      const path = event.composedPath()
      path.forEach(function (node) {
        elements.forEach(function (elem) {
          if (node === elem) {
            handler.call(elem, event)
          }
        })
      })
    },
    { capture: true, once }
  )
}

export default on
