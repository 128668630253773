/* eslint-disable no-use-before-define */
/* eslint-disable no-jquery/no-jquery-constructor */
/* eslint-disable no-jquery/no-other-utils */
/* global utag_data */

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { readCookie, setCookie, setCookies } from '../../utils/cookie_manager'

import { sendUtagEvent } from './utag_events'

dayjs.extend(customParseFormat)

export const EVENT_PARTNER_UPDATED = 'partner:updated'
const eventPartnerUpdated = new CustomEvent(EVENT_PARTNER_UPDATED, { detail: { isPartnerValid: true } })
const isFhiPage = document.querySelector('html').dataset.fhiTest !== undefined

$(function () {
  set_cookie_from_param('token_partner', 'token_partner', 1)
  set_cookie_from_param('idCliente', 'crm_id', 10)
  set_partner()

  set_userID()
  set_exact_target_cookies()
  set_cookie_from_param('affId', 'affId', 1)
  set_cookie_from_param('network', 'network', 1)
  if (IB.urlParser.getParam('cjevent') !== undefined && IB.urlParser.getParam('cp') === 'INGENIOUS' && IB.urlParser.getParam('utm_source') === 'ingenious') {
    const param_value = getUrlVars()['cjevent']
    setCookie('cjevent', param_value, 30, null, '.iberostar.com')
  }
  hide_myibe_references()
})

function set_partner() {
  const no_partner = getUrlVars()['NoPartner']
  if (typeof no_partner !== 'undefined') {
    exit_partner(function () {
      IB.partner.hidePartnerLogo()
    })
    return
  }

  if ($.cookie('cp') !== undefined) utag_data['campaign_cp_origin'] = $.cookie('cp')
  else if ($.cookie('cp_no_prom') !== undefined) utag_data['campaign_cp_origin'] = $.cookie('cp_no_prom')

  var cp = getUrlVars()['cp'] || ($.cookie('exit_partner') !== '1' ? $.cookie('campaign_cp_origin') : '')
  if (cp === '') cp = getUrlVars()['cp_no_prom'] || ($.cookie('exit_partner') !== '1' ? $.cookie('campaign_cp_no_prom_origin') : '')

  if (typeof cp !== 'undefined' && cp !== '') {
    const actual_cookie = readCookie('cp')
    const actual_cookie_no_prom = readCookie('cp_no_prom')

    if ((actual_cookie === null && actual_cookie_no_prom === null) || (actual_cookie !== cp && actual_cookie_no_prom !== cp)) {
      $.ajax({
        dataType: 'JSON',
        type: 'GET',
        url: `/partners_duration/${cp}`,
      }).then(function (msg) {
        if (msg && msg.partner !== undefined && msg.partner.code === cp) {
          const is_promo = msg.partner.promotional !== undefined ? msg.partner.promotional : false
          const promo_expires = is_promo ? msg.partner.cookies_duration : '-1'
          const no_promo_expires = is_promo ? '-1' : msg.partner.cookies_duration

          const cookies = [
            { name: 'exit_partner', value: '1', expires: '-1' },
            { name: 'cp', value: msg.partner.code, expires: promo_expires },
            { name: 'cp_partpdv', value: msg.partner.partpdv, expires: promo_expires },
            { name: 'cp_name', value: msg.partner.name, expires: promo_expires },
            { name: 'cp_image', value: msg.partner.image_url, expires: promo_expires },
            { name: 'cp_no_prom', value: msg.partner.code, expires: no_promo_expires },
            { name: 'cp_no_prom_partpdv', value: msg.partner.partpdv, expires: no_promo_expires },
            { name: 'cp_no_prom_name', value: msg.partner.name, expires: no_promo_expires },
            { name: 'cp_no_prom_image', value: msg.partner.image_url, expires: no_promo_expires },
            { name: 'cp_expires_at', value: dayjs().add(msg.partner.cookies_duration, 'days').format('YYYYMMDDHHmm'), expires: msg.partner.cookies_duration },
            { name: 'cp_promotional', value: msg.partner.promotional, expires: msg.partner.cookies_duration },
            { name: 'cp_to_crs', value: msg.partner.code, expires: msg.partner.cookies_duration },
          ]

          if (actual_cookie !== null && actual_cookie !== '') {
            cookies.push({ name: 'cppv', value: actual_cookie, expires: msg.partner.cookies_duration })
          }
          if (actual_cookie_no_prom !== null && actual_cookie_no_prom !== '') {
            cookies.push({ name: 'cp_no_prom_pv', value: actual_cookie_no_prom, expires: msg.partner.cookies_duration })
          }

          const base_domain = document.domain.split('.').slice(-2).join('.')

          if (is_promo) cookies.push({ name: 'campaign_cp_no_prom', value: msg.partner.code, domain: `.${base_domain}`, expires: '-1' })
          else {
            cookies.push({ name: 'campaign_cp', value: msg.partner.code, domain: `.${base_domain}`, expires: '-1' })
            cookies.push({ name: 'campaign_cp_no_prom', value: msg.partner.code, domain: `.${base_domain}` })
          }
          utag_data['campaign_cp'] = msg.partner.code

          if (is_promo) {
            cookies.push({ name: 'campaign_cp_origin', value: msg.partner.code, domain: `.${base_domain}` })
            cookies.push({ name: 'campaign_cp_no_prom_origin', value: msg.partner.code, domain: `.${base_domain}`, expires: '-1' })
          } else {
            cookies.push({ name: 'campaign_cp_origin', value: msg.partner.code, domain: `.${base_domain}`, expires: '-1' })
            cookies.push({ name: 'campaign_cp_no_prom_origin', value: msg.partner.code, domain: `.${base_domain}` })
          }

          const tduid = getUrlVars()['tduid']
          if (typeof tduid !== 'undefined') {
            cookies.push({ name: 'cp_tduid', value: tduid, expires: promo_expires })
            cookies.push({ name: 'cp_no_prom_tduid', value: tduid, expires: no_promo_expires })
          }

          setCookies(cookies, function () {
            try {
              IB.partner.updatePartner()
              IB.partner.showPartnerLogo()
              document.dispatchEvent(eventPartnerUpdated)
              IB.segmented_messages_banner.init()
            } catch (error) {
              // Error
            }
          })

          utag_data['campaign_cp_origin'] = cp
        }
      })
    }
  }
}

export function add_user_info(utag_user_data) {
  if (utag_user_data !== null && typeof utag_data !== 'undefined' && utag_data !== null) {
    if (noEmpty($.cookie('user_id'))) {
      const user_id = $.cookie('user_id') === 'not set' || $.cookie('user_id') === 'uninformed' ? '' : $.cookie('user_id')
      utag_user_data['user_id'] = user_id
    } else {
      utag_user_data['user_id'] = ''
    }
    if (noEmpty($.cookie('user_points'))) {
      const user_points = $.cookie('user_points') === 'not set' ? 'uninformed' : $.cookie('user_points')
      utag_user_data['user_points'] = user_points
    }
    if (noEmpty($.cookie('user_level'))) {
      const user_level = $.cookie('user_level') === 'not set' ? 'uninformed' : $.cookie('user_level')
      utag_user_data['user_level'] = user_level
    }
    if (noEmpty($.cookie('visitor_type'))) {
      const visitor_type = $.cookie('visitor_type') === 'not set' ? 'uninformed' : $.cookie('visitor_type')
      utag_user_data['visitor_type'] = visitor_type
    }
    if (utag_user_data.hasOwnProperty('points_redeemed') && $.cookie('points_redeemed') !== undefined) {
      utag_user_data['points_redeemed'] = $.cookie('points_redeemed')
    }

    const user_id = getUrlVars()['idCliente']
    if (user_id !== null || user_id !== undefined) {
      const base_domain = document.domain.split('.').slice(-2).join('.')
      utag_user_data['user_id'] = user_id
      setCookie('user_id', user_id, null, null, `.${base_domain}`)
    }

    sessionStorage.setItem('utag_user', JSON.stringify(utag_user_data))
    utag_data = $.extend({}, utag_data, utag_user_data)

    if (utag_data['page_is_logged'] === 'true' || $.cookie('my_iberostar_discount') === 'true') utag_data['promo_is_miiberostar'] = 'true'
    else utag_data['promo_is_miiberostar'] = 'false'
  }
}

function set_cookie_from_param(param_name, cookie_name, expires) {
  const param_value = getUrlVars()[param_name]
  if (typeof param_value !== 'undefined') {
    const actual_cookie = readCookie(cookie_name)
    if (!actual_cookie || actual_cookie.value !== param_value) {
      setCookie(cookie_name, param_value, expires)
    }
  }
}

export function exit_partner(callback) {
  IB.cookieManager.deleteCookies(
    [
      'cp',
      'cp_image',
      'cp_expires_at',
      'cppv',
      'exit_partner',
      'cp_name',
      'cp_promotional',
      'campaign_cp',
      'campaign_cp_origin',
      'campaign_cp_ck',
      'cp_partpdv',
      'cp_no_prom',
      'cp_no_prom_partpdv',
      'cp_no_prom_name',
      'cp_no_prom_image',
      'campaign_cp_no_prom',
      'campaign_cp_no_prom_origin',
      'cp_to_crs',
      'token_partner',
    ],
    callback
  )
}

function set_userID() {
  const idClient = getUrlVars()['idCliente']
  const cookieIdClientExpiration = 365

  if (typeof idClient !== 'undefined') {
    setCookie('idCliente', idClient, cookieIdClientExpiration)
  }
}

function set_exact_target_cookies() {
  const EXACT_TARGET_PARAMS = ['idCliente', 'CampaignId', 'j', 'e', 'l', 'u', 'jb', 'mid']
  for (let i = 0; i < EXACT_TARGET_PARAMS.length; i++) {
    const value = getUrlVars()[EXACT_TARGET_PARAMS[i]]
    if (typeof value === 'undefined') {
      return
    }
  }
  for (let i = 0; i < EXACT_TARGET_PARAMS.length; i++) {
    set_cookie_from_param(EXACT_TARGET_PARAMS[i], `exact_target_${EXACT_TARGET_PARAMS[i]}`, 30)
  }
}

export function getUrlVars() {
  const vars = {}
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value
  })
  return vars
}

export function noEmpty(value) {
  return value !== undefined && value !== null && value.length > 0
}

function hide_myibe_references() {
  let cp = readCookie('cp')
  if (!cp) cp = readCookie('cp_no_prom') !== null ? readCookie('cp_no_prom') : IB.urlParser.getParam('cp')
  const myibe_hide_cps = $('html').data('myibe-hide-cps')

  if (cp && myibe_hide_cps && myibe_hide_cps.indexOf(cp.toLowerCase()) != -1) {
    $('.js-myibe-hide').hide()
  }
}
