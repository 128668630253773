import { setCookie } from '../../utils/cookie_manager'

import { initializeSharedEvents } from './shared.analytics'

/**
 * Utag Stack sender variables
 */
let utagStack = []
let isUtagBeingObserved = false
const MAX_ATTEMPTS = 20

/**
 * Try to send a utag event. If utag not defined yet, store the event in a stack
 * and try to send it 20 times before cancelling.
 * @param {Object} { type, data }
 */
export const queueUtagEvent = ({ type = 'link', data }) => {
  if (typeof utag !== 'undefined') {
    // We use setTimeout in order to not use the main thread processing time
    // TODO: Change to Web Workers
    setTimeout(() => utag[type](data), 0)
  } else {
    utagStack.push({ type, data })

    if (isUtagBeingObserved) return

    isUtagBeingObserved = true
    let attempts = 0

    /* eslint-disable */
    function checkUtagDefined() {
      if ((typeof utag !== 'undefined' && typeof utag.gdpr !== 'undefined') || attempts === MAX_ATTEMPTS) {
        clearInterval(check_utag)

        utagStack.forEach(({ type, data }) => {
          utag[type](data)
        })

        utagStack = []
      }
      attempts += 1
    }
    /* eslint-enable */

    const check_utag = setInterval(checkUtagDefined, 500)
  }
}

const searcherEvents = new Set()

function checkOnceTimeEvent(eventData) {
  const onceTimeEvents = ['searcher', 'searcher fly']
  const eventStructure = ['sticky', 'body']
  if (onceTimeEvents.includes(eventData.event_cat) && eventStructure.includes(eventData.event_structure)) {
    const eventKey = `${eventData.event_cat}__${eventData.event_act}__${eventData.event_structure}`
    if (searcherEvents.has(eventKey)) {
      return true
    }
    searcherEvents.add(eventKey)
  }
  return false
}

/**
 * The function sendUtagEvent is used to send events to utag/GA4
 * @param {{type : 'link', data : { event_name: 'searcher_hotel', event_cat: 'searcher', event_act: 'discount', event_lbl: 'discount for residents', event_purpose: 'booking', event_structure: 'body'}}} dataObjectExample Example of data object
 */
export const sendUtagEvent = ({ type = 'link', data }) => {
  // Check if the event is a once time event
  if (checkOnceTimeEvent(data)) return
  IB.VisitorSessionId.initNewVisitorSessionId()
  queueUtagEvent({ type, data })
}

function sendSearchEvent() {
  // Not working in Safari, in Private Browsing Mode
  try {
    var utag_search_info = JSON.parse(sessionStorage.getItem('utag_search_info'))
    if (utag_search_info.search_term === 'not set') {
      utag_search_info.search_term = 'destination'
    }
    if (
      typeof utag_data !== 'undefined' &&
      (['funnel', 'experiences', 'search', 'offers', 'my iberostar', 'destination'].indexOf(utag_data.page_section) !=
        -1 ||
        (utag_data.page_section === 'hotel' && utag_search_info['event_lbl'] === 'without dates'))
    ) {
      if (typeof utag !== 'undefined') {
        sendUtagEvent({ data: utag_search_info })
        setCookie('fastbooking_data_utag', false, -1)
        sessionStorage.removeItem('utag_search_info')
      }
    }
  } catch (e) {
    console.warn(`Utag Send search event: ${e}`)
    console.warn(
      'Your web browser does not support storing settings locally. In Safari, the most common cause of this is using Private Browsing Mode. Some settings may not save or some features may not work properly for you.'
    )
  }
}

export function sendCatAndOptionalEvents(cat, info = {}) {
  const mandatory_events = {
    event_cat: cat,
  }

  const events = { ...mandatory_events, ...info }

  sendUtagEvent({ data: events })
}

export function get_utag_search_info() {
  if ($.cookie('fastbooking_data_utag') === undefined || $.cookie('fastbooking_data_utag') == '') {
    return
  }

  $.ajax({
    dataType: 'JSON',
    type: 'GET',
    url: '/ajax_contents/search_utag_info/',
    success(data) {
      // Not working in Safari, in Private Browsing Mode
      try {
        $.each(data, function (key, value) {
          if (value === '' || value === null || value === 'not set' || value === 'uninformed') {
            delete data[key]
          }
        })
        utag_data = $.extend({}, utag_data, data)
        sessionStorage.setItem('utag_search_info', JSON.stringify(data))
        sendSearchEvent()
      } catch (e) {
        console.log(`Utag utag_search_info: ${e}`)
        console.log(
          'Your web browser does not support storing settings locally. In Safari, the most common cause of this is using Private Browsing Mode. Some settings may not save or some features may not work properly for you.'
        )
      }
    },
  })
}

initializeSharedEvents()
