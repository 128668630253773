import dayjs from 'dayjs'

const htmlElement = document.documentElement

export const getCurrentLocale = () => htmlElement.dataset.jsLang

export const getCurrentHtmlLang = () => htmlElement.getAttribute('lang')

export const getPrefix = () => {
  const prefix = htmlElement.getAttribute('prefix')
  return prefix !== '/' ? prefix : ''
}

export const setSyncDayjsLocale = (lang = getCurrentLocale()) => {
  dayjs.locale(lang)
}

export const setAsyncDayjsLocale = (lang = getCurrentLocale(), callback) => {
  import(`dayjs/locale/${lang}.js`).then(() => {
    dayjs.locale(lang)
    callback && callback()
  })
}
